<template>
  <div>
    <Card>
      <template #header>
        <div class="baslik w-100">
          <div>Müşteriler</div>
          <div>
            <router-link to="customer/new">
              <button class="btn btn-primary d-flex justify-content-center align-items-center">
                <i class="flaticon2-plus mr-2"></i>
                <p style="font-size: 13px" class="text-white">
                  Müşteri Ekle
                </p>

              </button>
            </router-link>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <hr class="w-100" />
      </div>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Müşteriler Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.email="{ item }">
        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
      </template>
      <template v-slot:item.type="{ item }">
        <p class="text-dark" v-if="item.type === '1'">Bireysel</p>
        <p class="text-dark" v-else>Ticari</p>
      </template>
      <template v-slot:item.action="{ item }" >
        <div class="d-flex justify-content-end mx-2">
          <router-link :to="`customer/update/${item._id.$oid}/`">
          <button class="ml-auto">
            <i class="flaticon-eye icon-lg"></i>
          </button>
        </router-link>
        <button class=" border-0" @click="openDeleteHandler(item)">  <i class="flaticon2-trash text-dark text-md ml-4"></i></button>

        </div>
      </template>
    </List>
    <!-- Create and Actions Popups -->



    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteCustomer"
    />
    
  </Card>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CUSTOMERS } from "@/core/services/store/customers/customers.module";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      addFileModal2: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Türü", value: "type" },
        { text: "Ad/Soyad Ünvanı", value: "first_name" },
        { text: "Telefon", value: "phone" },
        { text: "E-Posta", value: "e_mail" },
        { text: "İlgilendiği Fiyat Aralığı", value: "price_range" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Müşteriler", route: "/manage/feature" },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.totalItems = 3;

      
      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `&Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      let payload = {myUser:this.myUser,query:query}
      this.$store.dispatch(CUSTOMERS,payload).then((response)=>{
        this.items = response
        this.pagination = {
          current_page: 1,
          total_pages: 1,
          total_items: response.length,
        };
      })
      this.loading = false;

    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `admin/customers`;
      this.deleteIds = `?user_token=${this.myUser}&customers_id=${item._id.$oid}`;
      this.$bvModal.show('modal-deleteOperationdeleteCustomer');
    },
    
    
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  components: {
    InsertUpdateOperations: () =>
      import("@/components/systemUser/modals/InsertUpdateOperations"),
    DetailView: () => import("@/components/systemUser/modals/DetailView"),
  },
  computed: {
    ...mapGetters([
      "myUser"
    ]),
   

  },
};
</script>

